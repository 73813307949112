import { useEffect } from "react";
import axios from 'axios'

export const RedirectIfNecessary = () => {
  useEffect(() => {
    const checkUser = async () => {
      try {
        const {data} = await axios.get('/api/v1/users/current/?fields=pending_sent_request');

        if (data && data.pending_sent_request === null || data.pending_sent_request === false) {
          window.location.href = '/views/home/';
        }
      } catch (e) {
        console.error(e);
      }
    }

    checkUser();
  }, []);

  return null;
};
